import cornerstone from 'cornerstone-core'
import cornerstoneWADOImageLoader from 'cornerstone-wado-image-loader'
import dicomParser from 'dicom-parser'
import cornerstoneTools from 'cornerstone-tools'
import Hammer from 'hammerjs'
import cornerstoneMath from 'cornerstone-math'
import moment from 'moment'

cornerstoneWADOImageLoader.external.cornerstone = cornerstone;
cornerstoneWADOImageLoader.external.dicomParser = dicomParser;
cornerstoneTools.external.cornerstone = cornerstone;
cornerstoneTools.external.Hammer = Hammer;
cornerstoneTools.external.cornerstoneMath = cornerstoneMath;
cornerstoneTools.init();
cornerstoneTools.toolStyle.setToolWidth(2);
cornerstoneTools.toolColors.setToolColor('rgb(255, 255, 0)');
cornerstoneTools.toolColors.setActiveColor('rgb(0, 255, 0)');

const WwwcTool = cornerstoneTools.WwwcTool;
const LengthTool = cornerstoneTools.LengthTool;
const MagnifyTool = cornerstoneTools.MagnifyTool;
const PanTool = cornerstoneTools.PanTool;
const RotateTool = cornerstoneTools.RotateTool;
const ZoomTouchPinchTool = cornerstoneTools.ZoomTouchPinchTool;
const PanMultiTouchTool = cornerstoneTools.PanMultiTouchTool;
const ZoomMouseWheelTool = cornerstoneTools.ZoomMouseWheelTool;
const AngleTool = cornerstoneTools.AngleTool;
const CobbAngleTool = cornerstoneTools.CobbAngleTool;



export function verAlerta(mensaje, tipo) {
  let alerta = document.getElementById("Alerta")
  alerta.innerHTML = mensaje
  alerta.classList.add(tipo)
  setTimeout(() => {
    alerta.innerHTML = ""
    alerta.classList.remove(tipo)
  }, 2000);
}
export async function CargarImagenes(imagenes, setPrimeraImagen, setListaImages, señal) {
  let Img_IDs = imagenes.FILES.map((UID) => { return UID.INS_UID })
  let configuracion = JSON.parse(window.localStorage.getItem("ConfiguracionGeneral"))
  let Arr = []
  for (let i = 0; i < Img_IDs.length; i++) {
    const element = Img_IDs[i];
    let req = await fetch(`${configuracion.ServidorDicom}/getfiles/${element}`, { señal })
    let res = await req.blob()
    if (señal.aborted) {
      break;
    }
    let imgID = cornerstoneWADOImageLoader.wadouri.fileManager.add(res)
    Arr.push(imgID)
    setListaImages([...Arr])
    if (i === 0) {
      setPrimeraImagen(true)
    }
  }
}
let DICOMDATA = undefined
export async function verPrimeraImagen(ListaImages, ImgNav, VistaIndex, setDatosDicom) {
  let element = document.getElementsByClassName("Dicom")[VistaIndex]
  cornerstone.disable(element)
  cornerstone.enable(element)
  cornerstoneTools.addTool(WwwcTool)
  cornerstoneTools.addTool(LengthTool)
  cornerstoneTools.addTool(ZoomTouchPinchTool)
  cornerstoneTools.addTool(PanMultiTouchTool)
  cornerstoneTools.addTool(MagnifyTool)
  cornerstoneTools.addTool(PanTool)
  cornerstoneTools.addTool(RotateTool)
  cornerstoneTools.addTool(ZoomMouseWheelTool)
  cornerstoneTools.addTool(AngleTool)
  cornerstoneTools.addTool(CobbAngleTool)
  cornerstoneTools.setToolActive('ZoomTouchPinch', {})
  cornerstoneTools.setToolActive('PanMultiTouch', {})
  let imagen = await cornerstone.loadImage(ListaImages[ImgNav])
  DICOMDATA = imagen.data
  setDatosDicom(imagen.data);
  cornerstone.displayImage(element, imagen);
  window.sessionStorage.setItem("DefaultViewPort", JSON.stringify(cornerstone.getViewport(element)))
}


export async function verimagen(ListaImages, ImgNav, VistaIndex) {
  let element = document.getElementsByClassName("Dicom")[VistaIndex]
  let imagen = await cornerstone.loadImage(ListaImages[ImgNav])
  cornerstone.displayImage(element, imagen);
}

export function activarHerramientas(herramienta, herramientaD) {
  let OS = JSON.parse(window.localStorage.getItem("OsDevice"))
  if (OS.mobil) {
    //cornerstoneTools.setToolDisabled(herramienta.previa, { mouseButtonMask: 1 })
    cornerstoneTools.setToolActive(herramienta.actual, { mouseButtonMask: 1 })
  } else {
    cornerstoneTools.setToolDisabled(herramientaD[0].previa, { mouseButtonMask: 1 })
    cornerstoneTools.setToolActive(herramientaD[0].actual, { mouseButtonMask: 1 })
    cornerstoneTools.setToolDisabled(herramientaD[1].previa, {})
    cornerstoneTools.setToolActive(herramientaD[1].actual, {})
    //cornerstoneTools.setToolDisabled(herramientaD[2].previa, { mouseButtonMask: 2 })
    cornerstoneTools.setToolActive(herramientaD[2].actual, { mouseButtonMask: 2 })
  }
}

export function incNav(ImgNav, ListaImages, imagenes, setImgNav) {
  if (ImgNav + 1 < ListaImages.length) {
    setImgNav(ImgNav + 1);
  } else {
    if (ListaImages.length === imagenes.FILES.length) {
      verAlerta("Ultima Imagen", "Peligro");
    } else {
      verAlerta("Descargando Imagenes", "Advertencia");
    }
  }
}

export function decNav(ImgNav, setImgNav) {
  if (ImgNav - 1 >= 0) {
    setImgNav(ImgNav - 1);
  } else {
    verAlerta("Primera Imagen", "Peligro");
  }
}

async function ColectorDeBasura(code){
  let storage = window.localStorage
  let keys = Object.keys(storage)
  //find all keys that include printConfig
  let printKeys = keys.filter((key)=>key.includes("printConfig"))
  //if printKeys are diferent to printConfig+code then delete
  if(printKeys.length > 0){
    printKeys.forEach((key)=>{
      if(key !== `printConfig${code}`){
        storage.removeItem(key)
      }
    })
  }
}
export async function printConfig(printCode,data,dicom){
  function espera(){
    return new Promise((resolve)=>{
      setTimeout(()=>{
        resolve()
      },2000)
    })
  }
  data.FECHA = moment(data.FECHA,"YYYYMMDD").format("DD/MM/YYYY")
  ColectorDeBasura(printCode)
  let contador = 0
  while(DICOMDATA === undefined && contador < 10){
    await espera()
    contador++
  }
  data.EDAD = DICOMDATA.string("x00101010")?DICOMDATA.string("x00101010"):"ND"
  window.localStorage.setItem(`printConfig${printCode}`, JSON.stringify(data))
  console.log("Configuracion Guardada")
}
export function guardarCaptura(MarcoSeleccioando,printCode) {
  let blob
  MarcoSeleccioando.toBlob((b) => {
    blob = b
    let url = URL.createObjectURL(blob)
    let capturas = window.localStorage.getItem(`printData${printCode}`)
    if (capturas) {
      capturas = JSON.parse(capturas)
      capturas.push(url)
      window.localStorage.setItem(`printData${printCode}`, JSON.stringify(capturas))
    } else {
      window.localStorage.setItem(`printData${printCode}`, JSON.stringify([url]))
    }
  })
}
export function LimpiarCapturas(printCode) {
  window.localStorage.removeItem(`printData${printCode}`)
  window.localStorage.removeItem(`printConfig${printCode}`)
}
export async function GetDicomData(ListaImages,ImgNav){
  let imagen = await cornerstone.loadImage(ListaImages[ImgNav])
  console.log(imagen.data);
}